let token;

const setInterceptorToken = (newToken) => {
  token = newToken;
};
const requestHandler = (request) => {
  request.headers['Authorization'] = `Bearer ${token}`;
  return request;
};

export { requestHandler, setInterceptorToken };
