import React, { useState, useImperativeHandle, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ValidationError from '../../validationerror/ValidationError';
import { parseISO } from 'date-fns';
import { isoDate } from '../../../utils/dateUtil';
import enGb from 'date-fns/locale/en-GB';
import SaveOrDeleteButton from './SaveOrDeleteButton';
import CancelButton from './CancelButton';
import { isValidInput, isValidDateInput } from '../../../utils/inputUtil';
import {
  dirtyClasses,
  dirtyFocusedClasses,
} from '../../login/passcodeInputWrapperClasses';
import { pristineClasses, pristineFocusedClasses } from './projectFormClasses';

registerLocale('en-gb', enGb);

const ProjectForm = React.forwardRef(
  ({ eventName, project, handleSave, onRemove, onCancel }, ref) => {
    const [formChanged, setFormChanged] = useState(false);

    /* VALID UNTIL DATE FIELD */
    const [validUntilDate, setValidUntilDate] = useState(project?.valid || '');
    const [datePickerClassNames, setDatePickerClassNames] = useState(
      project?.valid ? dirtyClasses : pristineClasses
    );
    const [dateInputInvalid, setDateInputInvalid] = useState(
      project?.valid ? false : true
    );
    const [dateInputTouched, setDateInputTouched] = useState(
      project?.valid ? true : false
    );
    const dateInputLabelText = 'Valid until';
    const dateInputErrorText = 'Date is required. Format DD.MM.YYYY.';

    /* PROJECT NAME FIELD */
    const [projectNameValue, setProjectNameValue] = useState(
      project?.name || ''
    );
    const [projectNameInvalid, setProjectNameInvalid] = useState(
      project?.name ? false : true
    );
    const [projectNameTouched, setProjectNameTouched] = useState(
      project?.name ? true : false
    );
    const [projectNameClassNames, setProjectNameClassNames] = useState(
      project?.name ? dirtyClasses : pristineClasses
    );
    const projectNameLabelText = 'Project name';
    const projectNameErrorText = 'Project Name is required';

    /* COMPANY NAME FIELD */
    const [companyNameValue, setCompanyNameValue] = useState(
      project?.company || ''
    );
    const [companyNameInvalid, setCompanyNameInvalid] = useState(
      project?.company ? false : true
    );
    const [companyNameTouched, setCompanyNameTouched] = useState(
      project?.company ? true : false
    );
    const [companyNameClassNames, setCompanyNameClassNames] = useState(
      project?.company ? dirtyClasses : pristineClasses
    );
    const companyNameLabelText = 'Client Name';
    const companyNameErrorText = 'Client Name is required';

    /* ACCESS CODE FIELD */
    const [accessCodeValue, setAccessCodeValue] = useState(
      project?.password || ''
    );
    const [accessCodeInvalid, setAccessCodeInvalid] = useState(
      project?.password ? false : true
    );
    const [accessCodeTouched, setAccessCodeTouched] = useState(
      project?.password ? true : false
    );
    const [accessCodeClassNames, setAccessCodeClassNames] = useState(
      project?.password ? dirtyClasses : pristineClasses
    );
    const accessCodeLabelText = 'Access code';
    const accessCodeErrorText = 'Access code is required';

    useEffect(() => {
      setFormChanged(false);

      setValidUntilDate(project?.valid || '');
      setProjectNameValue(project?.name || '');
      setCompanyNameValue(project?.company || '');
      setAccessCodeValue(project?.password || '');

      setDatePickerClassNames(project?.valid ? dirtyClasses : pristineClasses);
      setProjectNameClassNames(project?.name ? dirtyClasses : pristineClasses);
      setCompanyNameClassNames(
        project?.company ? dirtyClasses : pristineClasses
      );
      setAccessCodeClassNames(
        project.password ? dirtyClasses : pristineClasses
      );

      setDateInputInvalid(project?.valid ? false : true);
      setProjectNameInvalid(project?.name ? false : true);
      setCompanyNameInvalid(project?.company ? false : true);
      setAccessCodeInvalid(project?.password ? false : true);

      setDateInputTouched(project?.valid ? true : false);
      setProjectNameTouched(project?.name ? true : false);
      setCompanyNameTouched(project?.company ? true : false);
      setAccessCodeTouched(project?.password ? true : false);
    }, [project]);

    useImperativeHandle(ref, () => {
      return {
        getDialogProjectValues,
      };
    });

    const getDialogProjectValues = () => {
      return {
        password: accessCodeValue,
        company: companyNameValue,
        name: projectNameValue,
        valid: validUntilDate,
      };
    };

    const handleDateChange = (date) => {
      setValidUntilDate(date);
      setDatePickerClassNames(dirtyClasses);
      setDateInputInvalid(!isValidDateInput(date));
      setDateInputTouched(true);
      setFormChanged(true);
    };

    const handleProjectNameChange = (event) => {
      setProjectNameValue(event.target.value);
      setProjectNameTouched(true);
      setProjectNameInvalid(!isValidInput(event.target.value));
      setFormChanged(true);
    };

    const handleProjectNameOnFocus = (event) => {
      if (event.target.value.length === 0) {
        setProjectNameClassNames(pristineFocusedClasses);
      } else {
        setProjectNameClassNames(dirtyFocusedClasses);
      }
    };

    const handleProjectNameOnBlur = (event) => {
      if (event.target.value.length === 0) {
        setProjectNameClassNames(pristineClasses);
      } else {
        setProjectNameClassNames(dirtyClasses);
      }
    };

    const handleCompanyNameChange = (event) => {
      setCompanyNameValue(event.target.value);
      setCompanyNameTouched(true);
      setCompanyNameInvalid(!isValidInput(event.target.value));
      setFormChanged(true);
    };

    const handleCompanyNameOnFocus = (event) => {
      if (event.target.value.length === 0) {
        setCompanyNameClassNames(pristineFocusedClasses);
      } else {
        setCompanyNameClassNames(dirtyFocusedClasses);
      }
    };

    const handleCompanyNameOnBlur = (event) => {
      if (event.target.value.length === 0) {
        setCompanyNameClassNames(pristineClasses);
      } else {
        setCompanyNameClassNames(dirtyClasses);
      }
    };

    const handleAccessCodeChange = (event) => {
      setAccessCodeValue(event.target.value);
      setAccessCodeTouched(true);
      setAccessCodeInvalid(!isValidInput(event.target.value));
      setFormChanged(true);
    };

    const handleAccessCodeOnFocus = (event) => {
      if (event.target.value.length === 0) {
        setAccessCodeClassNames(pristineFocusedClasses);
      } else {
        setAccessCodeClassNames(dirtyFocusedClasses);
      }
    };

    const handleAccessCodeOnBlur = (event) => {
      if (event.target.value.length === 0) {
        setAccessCodeClassNames(pristineClasses);
      } else {
        setAccessCodeClassNames(dirtyClasses);
      }
    };

    if (eventName === 'create' || eventName === 'update') {
      return (
        <>
          <div className="mdl-dialog__content">
            <form name="createUpdateForm">
              {/* COMPANY NAME FIELD */}
              <div
                className={companyNameClassNames}
                onBlur={handleCompanyNameOnBlur}
                onFocus={handleCompanyNameOnFocus}
              >
                <input
                  id="update-company-name"
                  className="mdl-textfield__input"
                  name="update-company-name"
                  value={companyNameValue}
                  onChange={handleCompanyNameChange}
                  required
                />
                <label
                  className="mdl-textfield__label"
                  htmlFor="update-company-name"
                >
                  {companyNameLabelText}
                </label>
                <ValidationError
                  errorText={companyNameErrorText}
                  inputId="update-company-name"
                  inputTouched={companyNameTouched}
                  inputValid={!companyNameInvalid}
                />
              </div>
              {/* PROJECT NAME FIELD */}
              <div
                className={projectNameClassNames}
                onBlur={handleProjectNameOnBlur}
                onFocus={handleProjectNameOnFocus}
              >
                <input
                  id="update-project-name"
                  className="mdl-textfield__input"
                  name="update-project-name"
                  value={projectNameValue}
                  onChange={handleProjectNameChange}
                  required
                />
                <label
                  className="mdl-textfield__label"
                  htmlFor="update-project-name"
                >
                  {projectNameLabelText}
                </label>
                <ValidationError
                  errorText={projectNameErrorText}
                  inputId="update-project-name"
                  inputTouched={projectNameTouched}
                  inputValid={!projectNameInvalid}
                />
              </div>
              {/* ACCESS CODE FIELD */}
              <div
                className={accessCodeClassNames}
                onBlur={handleAccessCodeOnBlur}
                onFocus={handleAccessCodeOnFocus}
              >
                <input
                  id="update-project-password"
                  className="mdl-textfield__input"
                  name="update-project-password"
                  value={accessCodeValue}
                  onChange={handleAccessCodeChange}
                  required
                />
                <label
                  className="mdl-textfield__label"
                  htmlFor="update-project-password"
                >
                  {accessCodeLabelText}
                </label>
                <ValidationError
                  errorText={accessCodeErrorText}
                  inputId="update-project-password"
                  inputTouched={accessCodeTouched}
                  inputValid={!accessCodeInvalid}
                />
              </div>
              {/* VALID UNTIL DATE FIELD */}
              <div className={datePickerClassNames}>
                <DatePicker
                  id="update-project-valid-date"
                  dateFormat="dd.MM.yyyy"
                  className="mdl-textfield__input at-input-container"
                  name="update-project-valid-date"
                  onChange={handleDateChange}
                  required
                  autoComplete="off"
                  selected={
                    validUntilDate ? parseISO(isoDate(validUntilDate)) : ''
                  }
                  minDate={new Date()}
                  locale="en-gb"
                />
                <label
                  className="mdl-textfield__label"
                  htmlFor="update-project-valid-date"
                >
                  {dateInputLabelText}
                </label>
                <ValidationError
                  errorText={dateInputErrorText}
                  inputId="update-project-valid-date"
                  inputTouched={dateInputTouched}
                  inputValid={!dateInputInvalid}
                />
              </div>
            </form>
          </div>
          <div className="mdl-dialog__actions">
            <SaveOrDeleteButton
              eventName={eventName}
              handleSave={handleSave}
              onRemove={onRemove}
              formValid={
                formChanged &&
                !dateInputInvalid &&
                !projectNameInvalid &&
                !companyNameInvalid &&
                !accessCodeInvalid
              }
            />
            <CancelButton onCancel={onCancel} />
          </div>
        </>
      );
    } else if (eventName === 'remove') {
      return (
        <>
          <div className="mdl-dialog__actions">
            <SaveOrDeleteButton
              eventName={eventName}
              handleSave={handleSave}
              onRemove={onRemove}
              formValid={true}
            />
            <CancelButton onCancel={onCancel} />
          </div>
        </>
      );
    } else {
      return null;
    }
  }
);

export default ProjectForm;
