import Axios from 'axios';
import {
  formatResponseProjectDate,
  formatRequestProjectDate,
} from '../utils/dateUtil';
import { requestHandler } from '../utils/interceptor';
import { projectsBaseUrl } from '../constants';

// TODO: Create authConfig in global state

const config = { handlerEnabled: true };

Axios.interceptors.request.use((request) => requestHandler(request));

const getProjects = async () => {
  const { data: projects } = await Axios.get(projectsBaseUrl);
  const formattedProjects = projects.map((project) =>
    formatResponseProjectDate(project)
  );
  return formattedProjects;
};


const createProject = async (project) => {
  const formattedRequestProject = formatRequestProjectDate(project);
  const { data: createdProject } = await Axios.post(
    projectsBaseUrl,
    formattedRequestProject,
    config
  );
  
  const formattedCreatedProject = formatResponseProjectDate(createdProject);
  return formattedCreatedProject;
};

const updateProject = async (project) => {
  const formattedRequestProject = formatRequestProjectDate(project);
  const { data: updatedProject } = await Axios.put(
    `${projectsBaseUrl}/${formattedRequestProject._id}`,
    formattedRequestProject,
    config
  );
  const formattedUpdatedProject = formatResponseProjectDate(updatedProject);
  return formattedUpdatedProject;
};

const removeProject = async (project) => {
  await Axios.delete(`${projectsBaseUrl}/${project._id}`, config);
  return project;
};

const startStream = async (project) => {
  const formattedRequestProject = formatRequestProjectDate(project);
  const { data: startedProject } = await Axios.put(
    `${projectsBaseUrl}/${formattedRequestProject._id}/start`,
    config
  );
  const formattedStartedProject = formatResponseProjectDate(startedProject);
  return formattedStartedProject;
};

const stopStream = async (project) => {
  const formattedRequestProject = formatRequestProjectDate(project);
  const { data: stoppedProject } = await Axios.put(
    `${projectsBaseUrl}/${formattedRequestProject._id}/stop`,
    config
  );
  const formattedStoppedProject = formatResponseProjectDate(stoppedProject);
  return formattedStoppedProject;
};

const getProjectStreamState = async (projectId) => {
  const endPoint = `${projectsBaseUrl}/${projectId}/state`;
  const streamStateResponse = await Axios.get(endPoint);
  return streamStateResponse.data.live_stream.state;
};

export default {
  getProjects,
  createProject,
  updateProject,
  removeProject,
  startStream,
  stopStream,
  getProjectStreamState,
};
