import { useEffect } from 'react';

const Logout = ({ removeToken }) => {
  useEffect(() => {
    removeToken();
  });
  return null;
};

export default Logout;
