import React from 'react';

const CancelButton = ({ onCancel }) => {
  return (
    <button
      type="button"
      id="project-dialog-cancel-btn"
      onClick={onCancel}
      className="mdl-button mdl-js-button"
    >
      Cancel
    </button>
  );
};

export default CancelButton;
