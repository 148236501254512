import React from 'react';
import eficodeLogoHeader from '../../resources/images/eficode_logo_header.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  const logoDivStyle = {
    backgroundImage: `url(${eficodeLogoHeader})`,
  };

  return (
    <>
      <header className="mdl-shadow--4dp">
        <div className="mdl-layout__header-row" style={logoDivStyle}>
          <h1 className="mdl-layout-title heading_hidden">EfiTube</h1>
          <nav className="mdl-navigation">
            <Link
              to="/logout"
              className="mdl-navigation__link logout"
              id="logout-link"
            >
              Logout
            </Link>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
