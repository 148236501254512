import moment from 'moment/moment.js';

const parseDate = (dateValue) =>
  moment(dateValue, ['DD.MM.YYYY', moment.ISO_8601]);

export const formatDate = (dateValue) =>
  parseDate(dateValue).format('DD.MM.YYYY');

export const isoDate = (dateValue) => parseDate(dateValue).toISOString();

export const isDateTodayOrLater = (date) => {
  const today = new Date();
  const inputDate = new Date(date);
  if (today.getFullYear() < inputDate.getFullYear()) {
    return true;
  }
  if (
    today.getFullYear() === inputDate.getFullYear() &&
    today.getMonth() < inputDate.getMonth()
  ) {
    return true;
  }
  if (
    today.getFullYear() === inputDate.getFullYear() &&
    today.getMonth() === inputDate.getMonth() &&
    today.getDate() <= inputDate.getDate()
  ) {
    return true;
  }
  return false;
};

export const formatRequestProjectDate = ({ created, valid, ...project }) => {
  return {
    ...project,
    valid: isoDate(valid),
  };
};

export const formatResponseProjectDate = ({ created, valid, ...project }) => {
  return {
    ...project,
    created: formatDate(created),
    valid: formatDate(valid),
  };
};
