import React from 'react';

const ValidationError = ({ inputTouched, inputValid, inputId, errorText }) => {
  if (!inputTouched || inputValid) {
    return null;
  }

  return (
    <span className="mdl-tooltip mdl-tooltip--validation" htmlFor={inputId}>
      <span>{errorText}</span>
    </span>
  );
};

export default ValidationError;
