export const invalidClasses =
  'mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-invalid';

export const invalidFocusedClasses =
  'mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-invalid is-focused';

export const dirtyFocusedClasses =
  'mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-focused';

export const dirtyClasses =
  'mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty';
