import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Login from './components/login/Login';
import Admin from './components/admin/Admin';
import { setInterceptorToken } from './utils/interceptor';
import Stream from './components/stream/Stream';
import Logout from './components/logout/Logout';
import loginService from './services/login';

const App = () => {
  const storage = window.localStorage;
  const userTokenKey = 'userToken';
  const userRoleKey = 'role';
  const [userRole, setUserRole] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginRedirect, setLoginRedirect] = useState(null);
  const [playbackURL, setPlaybackURL] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setPlaybackURL(storage.getItem('playbackURL'));
    const token = storage.getItem(userTokenKey);
    setLoggedIn(Boolean(token));
    setInterceptorToken(token);
    if (!loginRedirect) {
      const redirectFromStorage = storage.getItem('loginRedirect');
      if (redirectFromStorage) {
        setLoginRedirect(redirectFromStorage);
      }
    }
    if (!userRole) {
      const userRoleFromStorage = storage.getItem(userRoleKey);
      if (userRoleFromStorage) {
        setUserRole(storage.getItem(userRoleKey));
      }
    }
  }, [loginRedirect, storage, userRole]);

  const login = async (password) => {
    try {
      const response = await loginService.login(password);
      
      setToken(response.token, response.company);
      setInterceptorToken(response.token);
      setLoggedIn(true);
      setErrorMessage(null);
      if (response.company === 'admin') {
        storage.setItem('loginRedirect', `/admin`);
        setLoginRedirect(`/admin`);
      } else {
        const streamName = response.streamName;
        const playbackURL = response.playbackURL;
        const company = response.company.toLowerCase();
        const projectName = response.name.toLowerCase();
        setPlaybackURL(playbackURL);
        storage.setItem('playbackURL', playbackURL);

        storage.setItem(
          'loginRedirect',
          `/stream/${company}/${projectName}/${streamName}`
        );
        setLoginRedirect(`/stream/${company}/${projectName}/${streamName}`);
      }
    } catch (e) {
      if (e.message === 'Request failed with status code 401') {
        setErrorMessage('Invalid Access Code');
      } else {
        setErrorMessage('Unknown error');
      }
    }
  };

  const logout = () => {
    setUserRole(null);
    setLoggedIn(false);
    setLoginRedirect(null);
    storage.clear();
  };

  const setToken = (token, role) => {
    storage.setItem(userTokenKey, token);

    if (role === 'admin') {
      setUserRole(role);
      storage.setItem(userRoleKey, role);
    }
  };

  const commonClasses =
    'at-container mdl-layout mdl-js-layout mdl-layout--fixed-header';
    
  return (
    <div
      className={
        commonClasses + ' at-login-background'
      }
    >
      <Router>
        <Routes>
          <Route path="/admin" element=
            {loggedIn && userRole === 'admin' ? (
              <Admin removeToken={logout}/>
            ) : (
            <Navigate to="/login"/>
            )}
          />
          <Route path="/login" element=
            {!loginRedirect ? (
              <Login loginHandler={login} errorMessage={errorMessage} />
            ) : ( 
            <Navigate to={loginRedirect}/>
            )}
          />
          <Route path="/stream/:company/:projectName/:streamName" element=
            {loggedIn ? (
              <Stream removeToken={logout} playbackURL={playbackURL} />
            ) : (
              <Navigate to="/login" />
            )}
          />
          <Route path="/logout" element=
            {loggedIn ? (
              <Logout removeToken={logout} />
            ) : (
            <Navigate to="/login" />
       
            )}
          />
          <Route path="/" element={<Navigate to="/login"/>}></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
