import React, { useState } from 'react';
import ErrorMessage from './errormessage/ErrorMessage';
import ValidationError from '../validationerror/ValidationError';
import eficodeLogo from '../../resources/images/eficode_logo_black.svg';
import {
  invalidFocusedClasses,
  dirtyFocusedClasses,
  dirtyClasses,
  invalidClasses,
} from './passcodeInputWrapperClasses';

const Login = ({ loginHandler, errorMessage }) => {
  const [passcodeInput, setPasscodeInput] = useState('');
  const [inputTouched, setInputTouched] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [
    passwordWrapperDivClassNames,
    setPasswordWrapperDivClassNames,
  ] = useState(invalidClasses);

  const handleLoginFormChange = (event) => {
    setPasscodeInput(event.target.value);
    setInputTouched(true);
    validateInput(event.target.value);
  };

  const validateInput = (text) => {
    setInputValid(text.trim().length > 0);
  };

  const handleLoginFormSubmit = async (event) => {
    event.preventDefault();
    await loginHandler(passcodeInput);
  };

  const handleOnFocus = (event) => {
    if (event.target.value.length === 0) {
      setPasswordWrapperDivClassNames(invalidFocusedClasses);
    } else {
      setPasswordWrapperDivClassNames(dirtyFocusedClasses);
    }
  };

  const handleOnBlur = (event) => {
    if (event.target.value.length === 0) {
      setPasswordWrapperDivClassNames(invalidClasses);
    } else {
      setPasswordWrapperDivClassNames(dirtyClasses);
    }
  };

  return (
    <>
      <div className="mdl-layout__content at-login-center">
        <section className="at-login-container">
          <div className="mdl-grid">
            <div className="mdl-cell mdl-cell--12-col at-login-flex">
              <div className="at-login-logo">
                <img src={eficodeLogo} alt="eficode-logo" />
              </div>
              <div className="at-login-header">
                <h1>EfiTube</h1>
                <h2>Follow usability tests online</h2>
              </div>
            </div>
            <div className="mdl-cell mdl-cell--12-col at-login-div">
              <h3 className="at-login-title">Login</h3>
              <form name="login" noValidate className="at-content">
                <fieldset className="at-login-form">
                  <div
                    id="passcode-container"
                    className={passwordWrapperDivClassNames}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                  >
                    <input
                      id="passcode"
                      className="mdl-textfield__input"
                      name="passcode"
                      value={passcodeInput}
                      type="password"
                      onChange={handleLoginFormChange}
                      required
                    />
                    <label
                      htmlFor="passcode"
                      className={'mdl-textfield__label'}
                    >
                      Access code
                    </label>
                    <ValidationError
                      inputTouched={inputTouched}
                      inputValid={inputValid}
                      inputId="passcode"
                      errorText="Access code is required"
                    />
                  </div>
                  <p>
                    If you do not have an access code, please contact Eficode
                  </p>
                  <div className="at-login-button">
                    <button
                      className="mdl-button mdl-js-button mdl-button--primary"
                      type="submit"
                      disabled={!inputValid}
                      onClick={handleLoginFormSubmit}
                      id="login-button"
                    >
                      Login
                    </button>
                  </div>
                  <ErrorMessage errorMessage={errorMessage} />
                </fieldset>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
