import React from 'react';

const SaveOrDeleteButton = ({ eventName, handleSave, onRemove, formValid}) => {
 
  if (eventName === 'create' || eventName === 'update') {
    return (
      <button
        type="button"
        id="save-project-btn"
        disabled={!formValid}
        onClick={handleSave}
        className="mdl-button mdl-js-button mdl-button--primary"
      >
        Save
      </button>
    );
  }
  if (eventName === 'remove') {
    return (
      <button
        type="button"
        id="confirm-project-deletion-btn"
        onClick={onRemove}
        onClickCapture={(e) => {e.currentTarget.disabled = true}}
        className="mdl-button mdl-js-button mdl-button--primary"
      >
        Delete
      </button>
    );
  }
  return null;
};

export default SaveOrDeleteButton;
