import { isDateTodayOrLater } from './dateUtil';

export const isValidInput = (input) => {
  if (input === null || input === undefined || input.trim() === '') {
    return false;
  }
  return true;
};

// Input date should not be earlier than today
export const isValidDateInput = (dateInput) => {
  return isDateTodayOrLater(dateInput);
};
