import React from 'react';

const ErrorMessage = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }
  return (
    <div className="at-inline-error">
      <span>{errorMessage}</span>
    </div>
  );
};

export default ErrorMessage;
