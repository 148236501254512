import React from 'react';

const AdminErrorMessage = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }
  return (
    <>
      <div className="at-projects-table mdl-grid">
        <div className="mdl-cell mdl-cell--12-col">
          <div className="at-error-msg">
            <i className="icon-exclamation"></i>
            <span className="at-error-message">{errorMessage}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminErrorMessage;
