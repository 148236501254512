import React, { useState } from 'react';
import VideoJS from '../video/video'
import { useParams } from 'react-router-dom';
import waitingScreen from "../../resources/images/efitube_waitingscreen.gif"
import { useInterval } from '../../utils/useInterval';
//import { WOWZA_API_KEY } from '../../constants';


const Stream = ({ removeToken, playbackURL }) => {
  const name = useParams().projectName;
  const company = useParams().company;
  const [sourceAvailable, setSourceAvailable] = useState(true)
  
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    fluid:false,
    width: 1920,
    height: 1080,
    breakpoints: {
      tiny: 300,
      xsmall: 400,
      small: 500,
      medium: 600,
      large: 700,
      xlarge: 800,
      huge: 900
    },
    sources: [{
      src: playbackURL
    }]
  };
  

  const handlePlayerReady = (player) => {
      if(sourceAvailable===true){
      playerRef.current = player;}
    
    player.on('error', (err) => {
      setSourceAvailable(false)
      //player.dispose()
    })

    // You can handle player events here, for example:
    player.on('waiting', () => {
      //videojs.log('player is waiting');
    });

    if(sourceAvailable===false){
      player.dispose();
      player.on('dispose', () => {

      });
    }
  };

const check = useInterval(() => {
  function handleErrors(response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
}
    fetch(playbackURL)
        .then(handleErrors)
        .then(response => {
          if (response.status === 200){
            setSourceAvailable(true)
            clearInterval()
          }else{
            setSourceAvailable(false)
          }
        
      }).catch(error=>console.clear())
    }, 1000 * 10)



  

  return (
    <>
    
      <div className="at-flex-container">
        <div className="at-left-half">
          <div className="at-logo-container">
            <h1 className="at-header">EfiTube</h1>
            <h2 className="at-subheader">Follow usability tests online</h2>
          </div>
          <dl className="at-info-container">
            <dt className="at-info-label">{company}</dt>
            <dd className="at-info-text">{name}</dd>
          </dl>
          <div className="at-logout-container">
            <span className="at-side-logout" onClick={removeToken}>
              <i className="icon-logout"></i>
              <span className="at-side-logout__label">Logout</span>
            </span>
          </div>
        </div>
        <div className="at-right-half">
         {sourceAvailable ? <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> : <img className="video-js" src={waitingScreen} alt="efitube waitingscreen"/>}
        </div>
      </div>
      <div className="at-stream-history mdl-shadow--2dp">
        <div className="at-right-block">
          <h4 className="at-logout" onClick={removeToken}>
            Logout
          </h4>
        </div>
      </div>
    </>
  );
};

export default Stream;
