import { isoDate } from './dateUtil';

export const getStreamStateTextFromStreamState = (streamState) => {
  switch (streamState) {
    case 'stopped':
      return 'Not streaming';
    case 'starting':
      return 'Stream starting';
    case 'started':
      return 'Stream running';
    case 'stopping':
      return 'Stream stopping';
    default:
      return streamState;
  }
};

export const sortProjects = (projectsToSort) => {
  if (projectsToSort) {
    return projectsToSort.sort((a, b) => {
      if (a.streamState !== b.streamState) {
        if (a.streamState > b.streamState) {
          return 1;
        }
        if (a.streamState < b.streamState) {
          return -1;
        }
      }
      return Date.parse(isoDate(b.created)) - Date.parse(isoDate(a.created));
    });
  }
};

export const copyToClipboard = (value) => () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value.trim());
  } else {
    const text = document.createElement('textarea');
    text.value = value.trim();
    document.body.appendChild(text);
    text.focus();
    text.select();
    document.execCommand('copy');
    document.body.removeChild(text);
  }
};

export const getStreamClassName = (streamState) => {
  if (streamState === 'started') {
    return 'at-stream-running';
  }
  if (streamState === 'starting') {
    return 'at-stream-starting';
  }
  return '';
};

export const getBottomRowClassName = (streamState) => {
  if (streamState === 'started') {
    return 'at-bottom at-stream-running';
  }
  if (streamState === 'starting') {
    return 'at-bottom at-stream-starting';
  }
  return 'at-bottom';
};

export const getClassNameForNonStoppedStream = (streamState) => {
  if (streamState !== 'stopped') {
    return 'stream-state-running';
  }
  return '';
};

export const getIconNameForStartStopButton = (streamState) => {
  return streamState === 'stopped' ? 'icon-start' : 'icon-stop';
};

export const getTextForStartStopButton = (streamState) => {
  return streamState === 'stopped' ? 'Start stream' : 'Stop stream';
};

export const getClassNameForStartStopButton = (streamState) => {
  const baseClasses = 'mdl-button mdl-js-button mdl-button--icon';
  return streamState === 'stopped'
    ? baseClasses + ' start-stream-btn'
    : baseClasses + ' stop-stream-btn';
};
