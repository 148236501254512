import React, { useEffect, useState, useRef } from 'react';
import AdminErrorMessage from './AdminErrorMessage';
import projectService from '../../services/projects';
import Dialog from '../dialog/Dialog';
import Header from '../header/Header';
import cloneDeep from 'lodash.clonedeep';
import {
  getBottomRowClassName,
  getClassNameForNonStoppedStream,
  getStreamStateTextFromStreamState,
  sortProjects,
  copyToClipboard,
  getStreamClassName,
  getIconNameForStartStopButton,
  getTextForStartStopButton,
  getClassNameForStartStopButton,
} from '../../utils/adminUtil';

const Admin = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [projects, setProjects] = useState([]);
  const [dialogEventName, setDialogEventName] = useState(null);
  const [dialogProject, setDialogProject] = useState({});
  const [intervalIds, setIntervalIds] = useState([]);
  const cancelSpecificInterval = useRef();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const fetchedProjects = await projectService.getProjects();
        setProjects(fetchedProjects);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    fetchProjects();
    const cancelAllIntervals = () => {
      intervalIds.forEach((intervalObject) => {
        const intervalId = intervalObject.interval;
        clearInterval(intervalId);
      });
      setIntervalIds([]);
    };
    return cancelAllIntervals;
  }, []);

  // Needed for intervalIds' state to get updated properly
  useEffect(() => {
    cancelSpecificInterval.current = (projectId) => {
      cancelIntervalWithProjectId(projectId);
    };
  });

  const dialogRef = React.createRef();
  

  const showDialog = () => {
    dialogRef.current.showDialog();
  };

  const closeDialog = () => {
    if(dialogRef.current){
      dialogRef.current.closeDialog();
      setDialogProject({});
    }else{setTimeout(()=>closeDialog,200)}
  };

  const openDialog = (eventName, project = {}) => () => {
    setDialogEventName(eventName);
    if (eventName !== 'create') {
      setDialogProject(cloneDeep(project));
    }
    showDialog();
    
  };

  const onCancel = () => {
    setErrorMessage(null);
    closeDialog();
  };

  const createOrUpdateProject = ({ eventName, projectToSave }) => {
    closeDialog();
    setErrorMessage('');

    if (projectToSave) {
      if (eventName === 'create') {
        projectService.createProject(projectToSave).then(
          (newProject) => {
            setProjects(projects.concat(newProject));
          },
          (error) => {
            setErrorMessage(error.message);
          }
        );
      } else {
        // These needs to be removed if exists, otherwise MongoDB fails with update.
        delete projectToSave.$resolved;
        delete projectToSave.$promise;

        projectService.updateProject(projectToSave).then(
          (updatedProject) => {
            const updatedIndex = projects.findIndex(
              ({ _id }) => _id === updatedProject._id
            );
            const updatedProjects = [
              ...projects.filter((elem, index) => index < updatedIndex),
              updatedProject,
              ...projects.filter((elem, index) => index > updatedIndex),
            ];
            setProjects(updatedProjects);
          },
          (error) => {
            setErrorMessage(error.message);
          }
        );
      }
    }
  };

  const removeProject = () => {
    projectService
      .removeProject(dialogProject)
      .then((removedProject) => {
        closeDialog();
        setProjects(projects.filter(({ _id }) => _id !== removedProject._id));
      })
      .catch((error) => {
        closeDialog();
        setErrorMessage(error.message);
      });
  };

  const updateProjectsState = (updatedProject) => {
    return projects.map((project) => {
      if (project._id === updatedProject._id) {
        return updatedProject;
      }
      return project;
    });
  };

  const cancelIntervalWithProjectId = (projectId) => {
    const intervalObject = intervalIds.find(
      (interval) => interval.projectId === projectId
    );
    const intervalId = intervalObject.interval;
    clearInterval(intervalId);
    setIntervalIds(
      intervalIds.filter((interval) => interval.projectId !== projectId)
    );
  };

  const insideInterval = async (project) => {
    try {
      const streamStateResponse = await projectService.getProjectStreamState(
        project._id
      );
      switch (streamStateResponse) {
        case 'started':
          cancelSpecificInterval.current(project._id);
          project.streamState = 'started';
          setProjects(updateProjectsState(project));
          break;
        case 'stopped':
          cancelSpecificInterval.current(project._id);
          project.streamState = 'stopped';
          setProjects(updateProjectsState(project));
          break;
        default:
          project.streamState = streamStateResponse;
          setProjects(updateProjectsState(project));
          break;
      }
    } catch (error) {
      setErrorMessage(error.message);
      project.streamState = 'stopped';
      setProjects(updateProjectsState(project));
      cancelSpecificInterval.current(project._id);
    }
  };

  const pollStreamState = (project) => {
    // Poll the stream state until it's started or stopped
    const intervalId = setInterval(insideInterval, 5000, project);
    setIntervalIds(
      intervalIds.concat({ projectId: project._id, interval: intervalId })
    );
  };

  const startStream = (project) => {
    projectService
      .startStream(project)
      .then((startedProject) => {
        setProjects(updateProjectsState(startedProject));
        pollStreamState(project);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const stopStream = (project) => {
    projectService
      .stopStream(project)
      .then((stoppedProject) => {
        setProjects(updateProjectsState(stoppedProject));
        pollStreamState(project);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const startOrStopStream = (project) => () => {
    if (project.streamState === 'stopped') {
      startStream(project);
    } else {
      stopStream(project);
    }
  };

  const ProjectRowOne = ({ project }) => {
    return (
      <>
        <tr className={getStreamClassName(project.streamState)}>
          <td data-title="Company">
            <span>{project.company}</span>
          </td>
          <td data-title="Project">
            <span>{project.name}</span>
          </td>
          <td data-title="Access Code">
            <span>{project.password}</span>{' '}
          </td>
          <td data-title="Stream URL" className="stream-info">
            <span className="at-url">
              {project.rtmpURL}
              <button
                className="mdl-button mdl-js-button mdl-button--icon"
                onClick={copyToClipboard(project.rtmpURL)}
              >
                <i className="icon-copy"></i>
              </button>
            </span>
            <br />
            <span>
              Stream: {project.streamName}
              <button
                className="mdl-button mdl-js-button mdl-button--icon"
                onClick={copyToClipboard(project.streamName)}
              >
                <i className="icon-copy"></i>
              </button>
            </span>
          </td>
          <td data-title="Created">
            <span>{project.created}</span>
          </td>
          <td data-title="Delete After">
            <span>{project.valid}</span>
          </td>
          <td data-title="Actions" className="at-align-right">
            <button
              className="mdl-button mdl-js-button mdl-button--icon update-project-btn"
              onClick={openDialog('update', project)}
              disabled={Boolean(
                project.streamState === 'started' ||
                  project.streamState === 'starting'
              )}
            >
              <i className="icon-edit"></i>
            </button>
            <button
              className="mdl-button mdl-js-button mdl-button--icon remove-project-btn"
              onClick={openDialog('remove', project)}
              title="Remove project"
              disabled={Boolean(
                project.streamState === 'started' ||
                  project.streamState === 'starting'
              )}
            >
              <i className="icon-trash"></i>
            </button>
          </td>
        </tr>
      </>
    );
  };

  const ProjectRowTwo = ({ project }) => {
    return (
      <>
        <tr className={getBottomRowClassName(project.streamState)}>
          <td valign="baseline" data-title="Stream State">
            <span
              className={getClassNameForNonStoppedStream(project.streamState)}
            >
              {getStreamStateTextFromStreamState(project.streamState)}
            </span>
          </td>
          <td valign="baseline" colSpan="6" data-title="Stream Control">
            <button
              className={getClassNameForStartStopButton(project.streamState)}
              disabled={project.streamState === 'starting'}
              onClick={startOrStopStream(project)}
            >
              <i
                className={getIconNameForStartStopButton(project.streamState)}
              ></i>
            </button>
            <span>{getTextForStartStopButton(project.streamState)}</span>
          </td>
        </tr>
      </>
    );
  };

  const ProjectRows = ({ project }) => {
    return (
      <>
        <ProjectRowOne project={project} />
        <ProjectRowTwo project={project} />
      </>
    );
  };

  if (!projects) {
    return null;
  }

  return (
    <>
      <Header />
      <div className="mdl-layout__content">
        <section className="at-main-container">
          <AdminErrorMessage errorMessage={errorMessage} />
          <div className="mdl-grid">
            <div className="mdl-cell mdl-cell--6-col mdl-cell--12-col-tablet">
              <h3>Projects</h3>
            </div>
            <div className="mdl-cell mdl-cell--6-col mdl-cell--12-col-tablet">
              <button
                id="at-create-project-btn"
                className="mdl-button mdl-js-button mdl-button--primary"
                onClick={openDialog('create')}
                aria-label="Create project"
              >
                Create Project
              </button>
            </div>
          </div>
          <div className="at-projects-table mdl-grid">
            <div className="mdl-cell mdl-cell--12-col ">
              <table className="at-responsive-table">
                <thead>
                  <tr className="at-bottom">
                    <th>Client</th>
                    <th>Project</th>
                    <th>Access Code</th>
                    <th>Stream URL for OBS</th>
                    <th>Created</th>
                    <th>Expires</th>
                    <th className="at-table-actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortProjects(projects).map((projectToRender) => {
                    return (
                      <ProjectRows
                        key={projectToRender._id}
                        project={projectToRender}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <Dialog
            dialogProject={dialogProject}
            onRemove={removeProject}
            onSave={createOrUpdateProject}
            onCancel={onCancel}
            eventName={dialogEventName}
            ref={dialogRef}
          />
        </section>
      </div>
    </>
  );
};

export default Admin;
