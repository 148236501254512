import React from 'react';

const ModalHeadingText = ({ eventName, project }) => {
  if (eventName === 'create') {
    return <span className="at-modal-heading-text">Create a new project</span>;
  }
  if (eventName === 'remove') {
    return (
      <span className="at-modal-heading-text">
        Confirm project <span className="at-project-name">{project.name}</span>{' '}
        removal
      </span>
    );
  }
  if (eventName === 'update') {
    return (
      <span className="at-modal-heading-text">
        Update project <span className="at-project-name">{project.name}</span>
      </span>
    );
  }
  return null;
};

const ModalHeading = ({ eventName, project }) => {
  return (
    <h4 className="mdl-dialog__title">
      <ModalHeadingText eventName={eventName} project={project} />
    </h4>
  );
};

export default ModalHeading;
