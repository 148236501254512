import React, { useImperativeHandle, useEffect } from 'react';
import ModalHeading from './projectForm/ModalHeading';
import dialogPolyfill from 'dialog-polyfill';
import ProjectForm from './projectForm/ProjectForm';

const Dialog = React.forwardRef(
  ({ eventName, dialogProject, onCancel, onSave, onRemove }, ref) => {
    const modalRef = React.createRef();

    useEffect(() => {
      // if (typeof HTMLDialogElement !== 'function') {
      //   dialogPolyfill.registerDialog(modalRef.current);
      // }
      if (modalRef && !modalRef.showDialog) {
        dialogPolyfill.registerDialog(modalRef.current);
      }
    });

    const showDialog = () => {
      modalRef.current.showModal();
    };
    const closeDialog = () => {
      modalRef.current.close();
    };

    const formRef = React.createRef();


    const getFormValues = () => {
      return formRef.current.getDialogProjectValues();
    };

    const handleSave = () => {
      const projectToSave = getFormValues();
      projectToSave._id = dialogProject._id;
      onSave({
        eventName,
        projectToSave,
      });
    };

    useImperativeHandle(ref, () => {
      return {
        showDialog,
        closeDialog,
      };
    });

    if (dialogProject) {
      return (
        <dialog
          ref={modalRef}
          id="confirmRemoveModal"
          className="mdl-dialog at-modal"
        >
          <ModalHeading eventName={eventName} project={dialogProject} />
          <ProjectForm
            eventName={eventName}
            project={dialogProject}
            ref={formRef}
            handleSave={handleSave}
            onRemove={onRemove}
            onCancel={onCancel}
          />
        </dialog>
      );
    }
    return null;
  }
);

export default Dialog;
